import React, { Component } from "react";
import { withRouter } from 'react-router';
import PropTypes from "prop-types";
import "typeface-roboto";

import { withStyles } from "@material-ui/core/styles";
import { CssBaseline, AppBar, Toolbar, Typography } from "@material-ui/core";

import { GlobalContext } from "../global-context";
import Routes from "../Routes";
import "./../index.css";

let log = false;

const drawerWidth = 320;

const styles = theme => ({
  root: {
    backgroundColor: '#fff',
    display: "flex",
  },
  logo: {
    filter: 'brightness(0%)',
    marginTop: '6px',
    marginLeft: theme.spacing(2),
    maxWidth: '90px'
  },
  avatar: {
    // margin: 10,
    display: "inline-flex",
  },
  toolbar: {
    background: 'linear-gradient(0deg, rgba(255,255,255,0), rgba(255,255,255,1))',
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  branch: {
    color: 'grey',
    position: 'absolute',
    fontSize: '0.7em',
    margin: '4px 0 0 4px'
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    maxHeight: '44px',
    backgroundColor: 'transparent',
    boxShadow: '0px 0px 0px 0px #fff',
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    textOverflow: 'ellipsis',
    // overflow: 'hidden', // this was causing the drawer to not scroll
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  content: {
    position: 'relative',
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
    overflowY: "scroll"
  },
  chartContainer: {
    marginLeft: -22,
  },
  tableContainer: {
    height: 320,
  },
  h5: {
    marginBottom: theme.spacing(2),
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
});

class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      showNotification: false,
      notificationVariant: '',
      notificationMessage: '',
      showRegionModal: false,
      version: "",
      branch: "",
      invalidCount: 0,
      enRouteCount: 0,
      liveCount: 0,
      returningCount: 0,
    };
  }

  handleInvalidCountChange = (count) => {
    this.setState({invalidCount: count});
  }

  handleEnRouteCountChange = (count) => {
    this.setState({enRouteCount: count});
  }

  handleLiveCountChange = (count) => {
    this.setState({liveCount: count});
  }

  handleReturningCountChange = (count) => {
    this.setState({returningCount: count});
  }

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    if (log) { console.log("close drawer") }
    this.setState({ open: false });
  };

  render() {
    const { classes } = this.props;

    return (
      <div>
        <GlobalContext.Consumer>
          {global => {
            return (
              <div className={classes.root}>

                <CssBaseline />
                <AppBar
                  position="absolute"
                  className={classes.appBar}
                >
                  <Toolbar
                    disableGutters={!this.state.open}
                    className={classes.toolbar}
                  >
                        <Typography
                          component="h1"
                          variant="h6"
                          color="inherit"
                          noWrap
                          className={classes.title}
                          style={{ marginLeft: "4px" }}
                        >
                              <img
                                className={classes.logo}
                                alt="bbm-logo"
                                hidden={this.state.open}
                                src="https://app.boothbymail.com/assets/img/BoothByMail_logo_monochrome%20[web-white-180].png"
                              />
                        </Typography>
                  </Toolbar>
                </AppBar>

                <main className={classes.content} ref={ref => global.mainRef = ref}>
                  <div style={{ width: '100%', height: '44px' }} />
                  <Routes />
                </main>
              </div>
            );
          }}
        </GlobalContext.Consumer>
      </div >
    );
  }
}

Layout.propTypes = {
  classes: PropTypes.object.isRequired,
};

Layout.contextType = GlobalContext;

export default withRouter(withStyles(styles)(Layout));