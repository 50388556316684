import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { IconButton, Typography, Snackbar } from '@material-ui/core';
import ShareOutlinedIcon from '@material-ui/icons/ShareOutlined';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import NoteOutlinedIcon from '@material-ui/icons/NoteOutlined';
import ViewCarouselOutlinedIcon from '@material-ui/icons/ViewCarouselOutlined';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import Slider from "react-slick";

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Post = ({ photos, title, caption, session, customLogo = '', logoUrl = '', fullWidth = false }) => {
  const swipablePhotos = photos.filter(photo => photo.name !== 'sign.png' && photo.name !== 'strip.jpg');
  const cls = useStyles({ fullWidth, hasMultiplePhotos: swipablePhotos.length > 1 });
  const [liked, setLiked] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const notePhoto = photos.find(photo => photo.name === 'sign.png');
  const stripPhoto = photos.find(photo => photo.name === 'strip.jpg');

  const settings = {
    dots: swipablePhotos.length > 1,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (index) => setCurrentSlide(index),
    appendDots: dots => (
      <div className={cls.dotsContainer}>
        <ul> {dots} </ul>
      </div>
    ),
    customPaging: i => (
      <div className={cls.dot} />
    )
  };

  const handleLike = () => setLiked(!liked);

  const handleShare = async () => {
    const sessionUrl = `${window.location.origin}/s/${session.id}`;

    if (navigator.share) {
      try {
        await navigator.share({
          text: 'Get $20 off your next BoothByMail order with code: GuestSession',
          url: sessionUrl,
        });
      } catch (err) {
        console.log('Error sharing:', err);
      }
    } else {
      console.log('Web Share API not supported');
    }
  };

  // Keep this function in case it's needed elsewhere
  const copyToClipboard = (text) => {
    if (navigator.clipboard && typeof navigator.clipboard.writeText === 'function') {
      navigator.clipboard.writeText(text)
        .then(() => {
          setSnackbarMessage('Link copied to clipboard!');
          setSnackbarOpen(true);
        })
        .catch((err) => {
          console.error('Failed to copy:', err);
          setSnackbarMessage('Failed to copy link. Please try again.');
          setSnackbarOpen(true);
        });
    } else {
      setSnackbarMessage('Clipboard access not available');
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleViewNote = () => {
    console.log('View note clicked for session:', session.id);
  };

  const handleViewStrip = () => {
    console.log('View strip clicked for session:', session.id);
  };

  return (
    <div className={cls.post}>
      <div className={cls.header}>
        <div className={cls.avatar}>
          {customLogo && logoUrl ? (
            <img src={logoUrl} alt="Brand logo" className={cls.avatarImage} />
          ) : (
            <div className={cls.defaultAvatar} />
          )}
        </div>
        <div className={cls.userInfo}>
          <span className={cls.username}>{caption}</span>
          <span className={cls.timestamp}>{title}</span>
        </div>
      </div>
      <div className={cls.sliderContainer}>
        <Slider {...settings}>
          {swipablePhotos.map((photo, index) => (
            <div key={photo.id} className={cls.imageContainer}>
              <img src={photo.full_url} alt={`Photo ${index + 1}`} className={cls.image} />
            </div>
          ))}
        </Slider>
        {swipablePhotos.length > 1 && (
          <div className={cls.photoCount}>
            <Typography variant="caption">
              {`${currentSlide + 1}/${swipablePhotos.length}`}
            </Typography>
          </div>
        )}
      </div>
      <div className={cls.footer}>
        <div className={cls.actions}>
          <div className={cls.leftActions}>
            <IconButton onClick={handleLike}>
              {liked ? (
                <FavoriteIcon className={cls.likedIcon} />
              ) : (
                <FavoriteBorderIcon className={cls.actionIcon} />
              )}
            </IconButton>
            <IconButton>
              <ChatBubbleOutlineIcon className={cls.actionIcon} />
            </IconButton>
            <IconButton onClick={handleShare}>
              <ShareOutlinedIcon className={cls.actionIcon} />
            </IconButton>
            {notePhoto && (
              <IconButton onClick={handleViewNote}>
                <NoteOutlinedIcon className={cls.actionIcon} />
              </IconButton>
            )}
            {stripPhoto && (
              <IconButton onClick={handleViewStrip}>
                <ViewCarouselOutlinedIcon className={cls.actionIcon} />
              </IconButton>
            )}
          </div>
          <div className={cls.rightActions}>
            <IconButton>
              <BookmarkBorderIcon className={cls.actionIcon} />
            </IconButton>
          </div>
        </div>
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
      />
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  post: props => ({
    backgroundColor: props.fullWidth ? 'transparent' : '#fff',
    borderRadius: props.fullWidth ? 0 : 8,
    boxShadow: props.fullWidth ? 'none' : '0 1px 2px rgba(0, 0, 0, 0.1)',
    overflow: 'hidden',
    width: '100%',
    marginBottom: theme.spacing(1),
  }),
  header: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2),
  },
  avatar: {
    width: 32,
    height: 32,
    borderRadius: '50%',
    marginRight: theme.spacing(1),
    overflow: 'hidden',
  },
  avatarImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  defaultAvatar: {
    width: '100%',
    height: '100%',
    backgroundColor: '#e0e0e0',
  },
  userInfo: {
    display: 'flex',
    flexDirection: 'column',
  },
  username: {
    fontWeight: 'bold',
    fontSize: 14,
  },
  timestamp: {
    fontSize: 12,
    color: '#8e8e8e',
  },
  imageContainer: {
    width: '100%',
    paddingTop: '100%',
    position: 'relative',
  },
  image: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  footer: props => ({
    padding: theme.spacing(0),
    paddingTop: props.hasMultiplePhotos ? theme.spacing(1) : theme.spacing(0),
  }),
  actions: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  leftActions: {
    display: 'flex',
  },
  rightActions: {
    marginLeft: 'auto',
  },
  likes: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
  },
  sliderContainer: props => ({
    position: 'relative',
    marginBottom: 0,
    '& .slick-slider': {
      '& .slick-dots': {
        bottom: props.hasMultiplePhotos ? -30 : 'auto',
        '& li': {
          margin: 0,
          width: 'auto',
          height: 'auto',
          '& button': {
            padding: 0,
            '&:before': {
              content: 'none',
            },
          },
        },
      },
    },
  }),
  photoCount: {
    position: 'absolute',
    top: 12,
    right: 12,
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    color: '#fff',
    borderRadius: 12,
    padding: '2px 8px',
    fontSize: 12,
    fontWeight: 'bold',
    zIndex: 1,
  },
  dotsContainer: {
    position: 'absolute',
    bottom: -30,
    left: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center',
    padding: '10px 0',
    '& ul': {
      padding: 0,
      margin: 0,
      display: 'flex',
      listStyle: 'none',
      justifyContent: 'center',
      width: '100%',
    },
  },
  dot: {
    width: 6,
    height: 6,
    margin: '0 5px',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    borderRadius: '50%',
    display: 'inline-block',
    transition: 'all 0.2s',
    '.slick-active &': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  actionIcon: {
    color: 'black',
  },
  likedIcon: {
    color: 'red',
  },
  copyButton: {
    marginTop: theme.spacing(1),
  },
}));

Post.propTypes = {
  photos: PropTypes.arrayOf(PropTypes.object).isRequired,
  title: PropTypes.string.isRequired,
  caption: PropTypes.string.isRequired,
  session: PropTypes.object.isRequired,
  customLogo: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  logoUrl: PropTypes.string,
  fullWidth: PropTypes.bool,
};

export default Post;