import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { AppBar, Toolbar, IconButton, Typography } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import RefreshIcon from '@material-ui/icons/Refresh';
import moment from 'moment';
import axios from 'axios';
import Post from '../Post';
import CustomContentLoader from '../CustomContentLoader';
import { Helmet } from 'react-helmet';
import defaultLogo from '../../static/apple-touch-icon-72x72.png';
import SessionPromo from './SessionPromo';

const log = false;

export default function Session(props) {
  const cls = useStyles();

  const [ sessionId ] = useState(props.sessionId || props.match?.params?.sessionid || null)
  const [ session, setSession ] = useState(null)

  const fetchSession = useCallback(async () => {
    const response = await axios({
      url: process.env.REACT_APP_GRAPHQL_URL,
      method: 'post',
      data: {
        query: `query getSessionById($session_id: String) {
          sessions(where: {id: {_eq: $session_id}}) {
            id
            layout_id
            createdat
            order {
              id
              album_key
              event_name
              custom_logo
              logo_url
            }
            photos(order_by: {sequence: asc}) {
              id
              name
              sequence
              smugmug_uri
              thumb_url
              thumb_height
              thumb_width
              thumb_size
              full_url
              full_height
              full_width
              full_size
              createdat
            }
          }
        }`,
        variables: { session_id: sessionId || '' }
      },
      headers: {
        'content-type': 'application/json',
      },
    });

    if (response && response.data && response.data.data && response.data.data.sessions && response.data.data.sessions.length > 0) {
      setSession(response.data.data.sessions[0])
      log && console.log(response)
    }
  }, [sessionId]);

  useEffect(() => {
    fetchSession();
  }, [fetchSession]);

  const handleBack = () => {
    // Implement navigation back to album screen
    window.location.href = `/a/${session.order.album_key}`;
  };

  const handleRefresh = () => {
    fetchSession();
  };

  return (
    <div className={cls.root}>
      {session && (
        <Helmet>
          <title>BoothByMail | {session.order.event_name}</title>
          <meta name="description" content={`Check out photos from ${session.order.event_name}`} />

          {/* Open Graph / Facebook */}
          <meta property="og:type" content="website" />
          <meta property="og:url" content={`https://photos.boothbymail.com/s/${session.id}`} />
          <meta property="og:title" content={`BoothByMail | ${session.order.event_name}`} />
          <meta property="og:description" content={`${session.order.event_name}`} />
          <meta property="og:image" content={session.photos[0].full_url} />
          <meta property="og:site_name" content="BoothByMail" />

          {/* Twitter */}
          <meta property="twitter:card" content="summary_large_image" />
          <meta property="twitter:url" content={`https://photos.boothbymail.com/s/${session.id}`} />
          <meta property="twitter:title" content={`BoothByMail | ${session.order.event_name}`} />
          <meta property="twitter:description" content={`${session.order.event_name}`} />
          <meta property="twitter:image" content={session.photos[0].full_url} />

          <meta name="medium" content="image" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@booth_by_mail" />
          <meta name="twitter:maxage" content="86400" />
          <meta name="twitter:image" content="https://photos.boothbymail.com/static/apple-touch-icon-180x180.png" />

          {/* Additional tags for better previews */}
          <meta name="theme-color" content="#ffffff" />
          <link rel="canonical" href={`https://photos.boothbymail.com/s/${session.id}`} />
        </Helmet>
      )}
      <AppBar position="static" className={cls.appBar}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={handleBack} className={cls.backButton}>
            <ChevronLeftIcon />
          </IconButton>
          <Typography variant="h6" className={cls.title}>
            Booth
          </Typography>
          <IconButton color="inherit" onClick={handleRefresh}>
            <RefreshIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <div className={cls.content}>
        {session ? (
          <Post
            photos={session.photos}
            title={moment(session.createdat).fromNow()}
            caption={session.order.event_name}
            session={session}
            customLogo={session.order.custom_logo}
            logoUrl={session.order.logo_url}
            fullWidth={true}
          />
        ) : (
          <CustomContentLoader
            speed={2}
            width={400}
            height={460}
            viewBox="0 0 400 460"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
          >
            <circle cx="31" cy="31" r="15" />
            <rect x="58" y="18" rx="2" ry="2" width="140" height="10" />
            <rect x="58" y="34" rx="2" ry="2" width="140" height="10" />
            <rect x="0" y="60" rx="2" ry="2" width="600" height="400" />
          </CustomContentLoader>
        )}
      </div>
      <SessionPromo session={session} />
    </div>
  );
}

Session.propTypes = {
  sessionId: PropTypes.string,
  match: PropTypes.shape({
    params: PropTypes.shape({
      sessionId: PropTypes.string
    })
  }),
  session: PropTypes.shape({
    photos: PropTypes.arrayOf(PropTypes.object).isRequired,
    createdat: PropTypes.string.isRequired,
    order: PropTypes.shape({
      event_name: PropTypes.string,
      custom_logo: PropTypes.string,
      logo_url: PropTypes.string,
    }),
  }),
};

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    boxShadow: 'none', // Remove the box shadow
    borderBottom: `1px solid ${theme.palette.divider}`, // Optional: add a subtle border instead
  },
  backButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    textAlign: 'center',
  },
  content: {
    maxWidth: 600,
    margin: '0 auto',
    padding: theme.spacing(0),
  },
}));
