import React, { useState, useEffect } from 'react';
import SessionPromoVariantA from './SessionPromoVariants/SessionPromoVariantA';
import SessionPromoVariantB from './SessionPromoVariants/SessionPromoVariantB';

// Assuming you have a function to track events
//import { trackEvent } from "../../utils/analytics";

const SessionPromo = React.memo(function SessionPromo({ session = {}, ...props }) {
  const [variant, setVariant] = useState(null);

  useEffect(() => {
    const chosenVariant = Math.random() < 0.5 ? 'A' : 'B';
    setVariant(chosenVariant);
    //trackEvent('promo_view', { variant: chosenVariant });
    console.log('Promo viewed: ', chosenVariant);
  }, []);

  const handlePromoClick = (variant) => {
    //trackEvent('promo_click', { variant });
    console.log('Promo clicked: ', variant);
  };

  const renderPromo = () => {
    if (variant === 'A') {
      return <SessionPromoVariantA session={session} onPromoClick={() => handlePromoClick('A')} />;
    } else if (variant === 'B') {
      return <SessionPromoVariantB session={session} onPromoClick={() => handlePromoClick('B')} />;
    }
    return null;
  };

  return (
    <div style={{ marginLeft: '20px', marginRight: '20px' }}>
      {renderPromo()}
    </div>
  );
});

export default SessionPromo;