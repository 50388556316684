import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import moment from 'moment';
import axios from 'axios';
import Post from '../Post';
import CustomContentLoader from '../CustomContentLoader';

const log = false;

export default function Album(props) {
  const cls = useStyles();

  const [ albumkey ] = useState(props.albumkey || props.match?.params?.albumkey || null)
  const [ order, setOrder ] = useState(null)

  useEffect(() => {
    async function fetchOrder() {
      const response = await axios({
        url: process.env.REACT_APP_GRAPHQL_URL,
        method: 'post',
        data: {
          query: `querygetOrderForPhotoSiteBySessionId($album_key: String)  {
            orders(where: {album_key: {_eq: $album_key}}) {
              id
              event_name
              event_date
              shipping_city
              shipping_state
              gallery_url
              custom_logo
              logo_url
              sessions(order_by: {createdat: desc}) {
                id
                layout_id
                createdat
                photos(order_by: {sequence: asc}) {
                  id
                  name
                  sequence
                  smugmug_uri
                  thumb_url
                  thumb_height
                  thumb_width
                  thumb_size
                  full_url
                  full_height
                  full_width
                  full_size
                  createdat
                }
              }
            }
          }
          `,
          variables: {album_key: albumkey}
        },
        headers: {
          'content-type': 'application/json',
        },
      });

      if (response && response.data && response.data.data && response.data.data.orders && response.data.data.orders.length > 0) {
        setOrder(response.data.data.orders[0])
        log && console.log(response)
      }
    }
    if (albumkey) fetchOrder()
  }, [albumkey]);

  return (
    <div className={cls.albumContainer}>
      {order && order.sessions ? (
        <div className={cls.postsContainer}>
          {order.sessions.map((session) => (
            session.photos.length > 0 && (
              <Post
                key={session.id}
                photos={session.photos}
                title={moment(session.createdat).fromNow()}
                caption={order.event_name}
                session={session}
                customLogo={order.custom_logo ? order.custom_logo.toString() : ''} // Convert to string if it's a boolean
                logoUrl={order.logo_url || ''}
                fullWidth={true}
              />
            )
          ))}
        </div>
      ) : (
        <CustomContentLoader
          speed={2}
          width={400}
          height={460}
          viewBox="0 0 400 460"
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
        >
          <circle cx="31" cy="31" r="15" />
          <rect x="58" y="18" rx="2" ry="2" width="140" height="10" />
          <rect x="58" y="34" rx="2" ry="2" width="140" height="10" />
          <rect x="0" y="60" rx="2" ry="2" width="600" height="400" />
        </CustomContentLoader>
      )}
    </div>
  );
}

Album.propTypes = {
  albumkey: PropTypes.string,
  match: PropTypes.shape({
    params: PropTypes.shape({
      albumkey: PropTypes.string
    })
  }),
  order: PropTypes.shape({
    sessions: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      photos: PropTypes.arrayOf(PropTypes.object).isRequired,
      createdat: PropTypes.string.isRequired,
    })),
    event_name: PropTypes.string,
    custom_logo: PropTypes.string,
    logo_url: PropTypes.string,
  }),
};

const useStyles = makeStyles(theme => ({
  albumContainer: {
    width: '100%',
    maxWidth: '100%',
    margin: '0 auto',
    padding: 0,
  },
  postsContainer: {
    display: 'flex',
    flexDirection: 'column',
    //gap: theme.spacing(1),
  },
}));