import React, { Component } from "react";
import "./index.css";
import { GlobalContext } from "./global-context";
import Layout from "./components/Layout";
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles'
import { withWidth } from '@material-ui/core'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'

const theme = createTheme({
  palette: {

    // Theme Colors
    primary: { light: `#80c8ff`, main: `#2893ef`, dark: `#186ab4`, contrastText: `#fff` },
    secondary: { light: `#a0a8b0`, main: `#505860`, dark: `#242830`, contrastText: `#fff` },
    warning: { light: `#ffb74d`, main: `#ff9800`, dark: `#f57c00`, contrastText: `#fff` },
    error: { light: `#ff8872`, main: `#f44436`, dark: `#c82218`, contrastText: `#fff` },
    success: { light: `#81c784`, main: `#4caf50`, dark: `#388e3c`, contrastText: `#fff` },
    info: { light: `#80c8ff`, main: `#2893ef`, dark: `#084887`, contrastText: `#fff` },

    // Text Colors
    text: {
      primary: `#000`,
      secondary: `#a0a8b0`,
      // Remove the tertiary property
      disabled: `#c0c8d0`,
      hint: `#c0c8d0`,
    },

    // Action Colors
    action: {
      //   active: `#aaa`,
      hover: `#f0f0f0`,
      //   selected: `#f0f0f0`,
      //   disabled: `#bbb`,
      //   disabledBackground: `#ddd`,
    },

    // Background Colors
    background: {
      default: `#ffffff`,
      paper: `#ffffff`,
    },

    // Border/Divider Colors
    // divider: `#ddd`,
  },

  typography: {},
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      theme: theme,
      width: props.width,
      notificationShow: false,
      notificationVariant: '',
      notificationMessage: '',
      handleNotifications: async (show, variant = this.state.notificationVariant, message = this.state.notificationMessage) => await this.setState(
        {
          notificationShow: show,
          notificationVariant: variant,
          notificationMessage: message
        }
      ),
    }
  }

  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <GlobalContext.Provider value={this.state}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <Layout />
            </MuiPickersUtilsProvider>
        </GlobalContext.Provider>
      </MuiThemeProvider>
    )
  }
}

export default withWidth()(App);
